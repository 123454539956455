html {
    scroll-behavior: smooth;
}

body{
    font-family: '33535gillsansmt' !important;
    background-color: '#f8f8f8' !important;      
}

header {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

header a {
    color: #fff;
}

header a:hover {
    color: #fff;
    text-decoration: none;
}

footer {
    color: #FFF;
}

.header-area {
    align-content: space-between;
    justify-items: center;
}


.icon-svg{
    max-width: 21px;
}

.nav-link-header {
    font-size: 12px;
    color: #fff;
}

.nav-item-header {
    padding-right: 5px;
    padding-left: 5px;
    border-right: 1px solid #CCC;
}

.badge-cart{
    position: relative;
    font-family: sans-serif;
    top: -15px;
    left: 40px;
}

.text-gold {
    color: #FABB41;
}

.text-gold:hover {
    color: #FABB41;
}